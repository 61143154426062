.user-kpis {
    height: 100%; /* Ensure it takes full height of the parent */
    width: 100%; /* Ensure it takes full width of the parent */
    display: flex;
    align-items: center; /* Align items at the start to avoid centering issues */
    justify-content: flex-start; /* Align items at the start to avoid centering issues */
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    scroll-behavior: smooth; /* Ensure smooth scrolling */
    padding: 16px; /* Add some padding if needed */
    box-sizing: border-box; /* Ensure padding doesn't affect the width/height */
}

.onboarding-metrics-row {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.total-and-email-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    height: 100%;
}

@media screen and (max-width: 992px) {
    .onboarding-metrics-row {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .total-and-email-column {
        justify-content: flex-start;
        gap: 10px;

        width: 100%;
        max-width: 500px;
        height: max-content;
    }
}