.ftd-listing-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  width: 100%;
  max-width: 1500px;
  
  padding-bottom: 50px;
  
  border-radius: 12px;
  background-color: var(--panelBackground);
}

.ftd-listing-table .header-text-container {
  align-items: center;
  gap: 50px;
}