.delete-row-popup {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 1;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-row-panel {
  width: 400px;

  padding: 30px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  border-radius: 16px;
  background-color: var(--darkGrey);
}

.delete-row-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.delete-row-panel-header p {
  color: var(--white);
}

.delete-row-panel form {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}


.delete-row-panel form .checkbox-container {
  display: flex;
  align-items: center;
}

.delete-row-panel form .checkbox-container .delete-check-box {
  cursor: pointer;
}

.delete-row-panel button.disabled {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: not-allowed;
}

.delete-row-panel .success-message,
.delete-row-panel .error-message {
  margin: 0;
}