.payments-body-container .cell-small {
  flex: 1;

  max-width: 25%;
  min-width: 25%;
  width: 25%;
}

.payments-body-container .cell-small .extra-small {
  font-size: 14px;
}

.payments-body-container .cell-small .cell-label {
  padding: 0 5px;
}

.payments-body-container .cell-small .cell-label .add-ip {
  cursor: pointer;
  padding: 0px 10px;

  font-size: 20px;

  border-radius: 5px;
  background-color: var(--primary);
}

.payments-body-container .expand-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  cursor: pointer;

  border-radius: 50%;
}

.payments-body-container .expand-button:hover {
  background-color: var(--tableHeaderBackground);
}

.payments-body-container .expanded-row {
  width: 100%;
  padding: 10px;

  box-sizing: border-box;

  background-color: var(--tableBodyBackground);
  border-top: 1px solid var(--panelDividerLine);
}

.payments-body-container .expanded-row .expanded-row-title {
  margin: 0 0 10px 0;

  text-align: center;
  color: var(--white);
}

.payments-body-container .expanded-row-labels {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.payments-body-container .expanded-row-labels span {
  color: var(--white);
  text-align: center;
}

.payments-body-container .cell-small .delete-icon {
  width: 20px;
  cursor: pointer;
}

.payments-body-container .cell-small .delete-icon:hover {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--tableHeaderBackground);
}