/* Container for the entire page */
.user-data {
  width: 100%;
  max-width: 550px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: var(--panelBackground);
  border-radius: 30px;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.header-text {
  color: var(--white);
  font-size: 20px;
}

.divider-line {
  height: 2px;
  width: 100%;
  background-color: var(--panelDividerLine);
}

.data-key-value-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.data-key-container {
  width: 25%;
  display: flex;
  flex-direction: row;;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 15px;
}

.data-key-text {
  color: var(--white);
  font-weight: 100;
}

.data-value-text {
  color: var(--lightGrey);
  overflow: hidden;
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
  margin-right: 10px;
}

.data-value-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 1400px) {
  .user-data {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1024) {
  .user-data {
    height: max-content;
  }
}