.suspend-user-popup.profit-boost-popup .suspend-user-header {
    display: flex;
    justify-content: space-between;
}

.suspend-user-popup.profit-boost-popup .suspend-user-header h2 {
    font-size: 24px;
    color: var(--white);
}

.profit-boost-body form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profit-boost-body .form-group {
    display: flex;
    flex-direction: column;
}

.profit-boost-body form .form-group label {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 5px;
}

.profit-boost-body form .form-group input,
.profit-boost-body form .form-group select {
    width: 416px;
    padding: 10px 12px 10px 12px;
    color: var(--lightGrey);
    background-color: var(--panelBackground);
    border: none;
    border-radius: 4px;
}

.profit-boost-body form .form-group select {
    width: 100%;
    font-family: 'Poppins-regular';
}

.profit-boost-body form button {
    width: 200px;
    height: 40px;
    margin-left: auto;
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profit-boost-body form button.button-sending {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: not-allowed;

    background-color: var(--darkOrangeButton);
    color: var(--primary);
}

.profit-boost-popup .error-message,
.profit-boost-popup .success-message {
    margin: 0 0 10px 0;
}