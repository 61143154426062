/* Container for the entire page */
.panel-header {
  width: 98%;
  height: 80px;
  background-color: var(--panelBackground);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
}

.header-tab {
  height: 50%;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--panelBackground);
  border: none;
  outline: none;
  margin-left: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.header-tab-selected {
  height: 50%;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border: none;
  outline: none;
  margin-left: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.header-tab:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--darkOrangeButton);
  border: none;
  outline: none;
}

.tab-text {
  color: var(--lightGrey);
}

.tab-text-selected {
  color: var(--white);
}
