/* Container for the dropdown */
.drop-down-container {
  position: relative;
  width: 180px;
}

.drop-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  background-color: var(--panelDividerLine);
  border-color: var(--panelDividerLine);
  color: var(--lightGrey);
  border-radius: 8px;
  border-style: solid;
  outline: none;
  cursor: pointer;
}

.drop-down:active {
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
}

.selected-item-text {
  color: var(--white);
}

.unselected-item-text {
  color: var(--lightGrey);
}

/* Dropdown list */
.drop-down-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--panelDividerLine);
  border-radius: 8px;
  border: 1px solid var(--panelDividerLine);
  z-index: 1000;
  margin-top: 8px; /* Adjust margin to control space between button and dropdown */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  max-height: 500px;
}

/* Individual dropdown list items */
.drop-down-list-item {
  padding: 10px;
  cursor: pointer;
  background-color: var(--panelBackground);
  color: var(--lightGrey);
  transition: background-color 0.3s ease;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-down-list-item:hover {
  background-color: var(--panelDividerLine);
  color: white;
}

.drop-down-list-item:not(:last-child) {
  border-bottom: 1px solid var(--panelDividerLine);
}

.drop-down-list-bigger {
  width: 500px;
  /* height: 400px; */

  padding: 10px;
  margin-top: 10px;
  position: absolute;

  background-color: var(--panelDividerLine);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  z-index: 1;
  overflow-y: scroll;
}

.drop-down-list-bigger .drop-down-list-bigger-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 10px;
  overflow-y: scroll;
  height: max-content;
  max-height: 330px;
}

.drop-down-list-bigger .drop-down-list-item {
  width: 28%;
  height: 15px;
  border: 1px solid var(--panelDividerLine);

  &:hover {
    background-color: var(--panelBackground);
  }
}

