.affiliate-panel-header {
  width: 100%;
  height: 80px;

  background-color: var(--panelBackground);
  border-radius: 12px;
  padding: 0 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  
}

.affiliate-panel-header .header-tab-selected,
.affiliate-panel-header .header-tab {
  margin: 0;
}