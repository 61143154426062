/* Container for the header */
.header {
  width: 100%;
  position: fixed;
  z-index: 2;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 10px 0;

  background-color: var(--panelBackground);
}

.user-button {
  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 34px;

  font-size: 20px;

  border-radius: 22px;
  background-color: #25252F;
  border: none;
  outline: none;
}

.logoImage {
  cursor: pointer;
}

.user-button:hover {
  background-color: #25252f84;
  cursor: pointer;
}

.user-button-text {
  color: var(--white)
}