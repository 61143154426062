.total-num-users {
    height: 200px; /* Ensure it takes full height of the parent */
    width: 300px; /* Ensure it takes full width of the parent */
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items at the start to avoid centering issues */
    justify-content: center;
    background-color: var(--panelBackground);
}

.total-num-users-left-half {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.total-num-users-right-half {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.total-users-icon {
    height: 80px;
    width: 80px
}

.total-users-label {
    color: var(--lightGrey);
    font-size: 16px;
}

.total-users-value {
    color: var(--white);
    font-size: 16px;
    margin-top: 30px;
}

@media screen and (max-width: 992px) {
    .total-num-users {
        width: 100%;
    }
}