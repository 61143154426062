.bet-kpis {
    height: 100%; /* Ensure it takes full height of the parent */
    width: 100%; /* Ensure it takes full width of the parent */
    display: flex;
    align-items: center; /* Align items at the start to avoid centering issues */
    justify-content: flex-start; /* Align items at the start to avoid centering issues */
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    scroll-behavior: smooth; /* Ensure smooth scrolling */
    /* padding-bottom: 90px; Add some padding if needed */
    box-sizing: border-box; /* Ensure padding doesn't affect the width/height */
}