
.tou-table-container .cell-small {
  flex: 1;

  max-width: 400px;
  min-width: 200px;
  width: auto;
}

.tou-table-container .cell-small .cell-label {
  padding: 0 5px;
}

@media screen and (max-width: 1500px) {
  .tou-table-container .table-row {
    width: max-content;
  }

  .tou-table-container .table-row .cell-small {
    max-width: 200px;
    min-width: 200px
  }
}

@media screen and (max-width: 992px) {
  .tou-table-container .table-row {
    width: max-content;
  }

  .tou-table-container .table-row .cell-small {
    max-width: 200px;
  }
}