.suspend-user-panel {
  width: max-content;
  max-width: 900px;
  height: max-content;
  min-height: 450px;

  padding: 30px;
}

.suspend-user-header {
  padding: 10px 0;
}

.suspend-user-header-label {
  padding-left: 0;
  font-size: 20px;
}

.personal-limits-tabs {
  width: 100%;

  margin-bottom: 20px;

  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.personal-limits-tab {
  padding: 5px;

  color: var(--white);
  font-size: 18px;
  cursor: pointer;
}

.personal-limits-tab.active {
  border-bottom: 1px solid var(--primary);
}


.personal-limits-body {
  width: 100%;
  min-width: 900px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.personal-limits-section-container {
  width: 48%;

  display: flex;
  flex-direction: column;

  border: 1px solid #7777774a;
  border-radius: 8px;
}

.personal-limits-section-header {
  width: 95%;

  padding: 10px 0;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--white);

  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 51.04%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
  box-sizing: border-box;
}

.personal-limits-edit-button {
  cursor: pointer;
}

.personal-limits-section-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

}

.personal-limits-section-item {
  width: 95%;
  box-sizing: border-box;

  margin: auto;
  padding: 15px 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.personal-limits-section-item :first-child {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.border-bottom {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 51.04%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;
}

.personal-limits-section-item-label {
  color: var(--lightGrey);
  font-size: 14px;
}

.personal-limits-section-item-value {
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}

.personal-limits-section-item .slider {
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  .personal-limits-body {
    width: 100%;
    min-width: 100%;
  }
}