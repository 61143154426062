.wagers-by-day-graph {
    width: 90%;
    min-height: 600px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--panelBackground);
}

.wagers-by-day-graph-header-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left-half-graph-header {
    width: 50%;
    display: flex;
    align-items: center;
}

.right-half-graph-header {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.wagers-by-day-graph-header-label {
    color: var(--white);
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 20px;
}

.wagers-graph-container {
    width: 95%;
    height: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
}