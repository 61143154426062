.financials-graph-container {
  display: flex;
  gap: 10px;

  width: 50%;
  height: 100%;
}

.financials-graph {
  width: 100%;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--panelBackground);
}

.financials-graph-header-row {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  
  box-sizing: border-box;
}
.financials-graph-header-row .react-datepicker-wrapper {
  margin-left: auto;
}

.financials-graph-header-row .right-half-graph-header {
  width: max-content;
}

.financials-graph-header-label {
  color: var(--white);
}

.financials-data-graph {
  width: 95%;
  height: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .financials-container .top-content {
    flex-direction: column;
  }

  .financials-stats-container,
  .financials-graph-container {
    width: 100%;
    justify-content: space-between;
  }

  .financials-stats-container .financials-stat {
    height: 150px;
  }

  .financials-table-row {
    width: max-content;

    margin: auto;
  }
}