

.editing-mode.time-limits .personal-limits-section-item-value {
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;

  padding: 16px;

  border-radius: 4px;
  border: 1px solid #7777774a;
}

.time-limit-decrease,
.time-limit-increase {
  width: 84px !important;

  padding: 8px 16px;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 51.04%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;

  color: var(--primary);
  font-size: 20px;
  text-align: center;
}

.time-limit-decrease.disabled,
.time-limit-increase.disabled,
.editing-mode.time-limits .personal-limits-section-item-value span.disabled {
  cursor: not-allowed;
  color: var(--lightGrey);
  border-color: var(--lightGrey);
}

.editing-mode.time-limits .personal-limits-section-item-value span {
  width: 98px;
  padding: 8px;

  text-align: center;

  border-radius: 4px;
  border: 1px solid var(--white);
}

.editing-mode.time-limits .personal-limits-section-container .slider {
  cursor: pointer;
}