#free-bet-data-chart {
    width: 90%;
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    padding: 24px;
    padding-top: 0;
    box-sizing: border-box;

    border-radius: 8px;
    background-color: var(--panelBackground);
}

.free-bet-data-chart-header {
    width: 100%;
    
    display: flex;
    align-items: center;
}

.free-bet-data-chart-header .free-bet-data-chart-kpis {
    display: flex;
}

.free-bet-data-chart-header .dropdowns-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.free-bet-data-chart-kpi {
    display: flex;
    flex-direction: column;

    margin-right: 24px;
    padding-top: 24px;
}

.free-bet-data-chart-kpi.active {
    border-top: 2px solid #FD6F27;
}

.free-bet-data-chart-kpi-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--lightGrey);
    text-transform: uppercase;
}

.free-bet-data-chart-kpi.active .free-bet-data-chart-kpi-title {
    color: var(--primary);
}

.free-bet-data-chart-kpi-value {
    display: flex;
    align-items: baseline;
    gap: 5px;

    margin-top: 10px;

    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--white);
}

.free-bet-data-chart-body {
    width: 100%;
    height: 300px;

    display: flex;
    justify-content: flex-start;
}

.free-bet-data-chart-body .left-content {
    width: 80%;
}

.free-bet-data-chart-body .right-content {
    width: 20%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.free-bet-data-chart-body .right-content .stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.free-bet-data-chart-body .right-content .stat .stat-title {
    display: flex;
    align-items: center;

    color: var(--lightGrey);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.003em;
}

.free-bet-data-chart-body .right-content .stat .stat-title .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
}

.free-bet-data-chart-body .right-content .stat .stat-value {
    color: var(--white);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.003em;
    text-align: left;
}

#free-bet-data-chart .drop-down-container {
    margin-left: 0;
}

#freebet-chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: var(--darkGrey);
    color: white;
    border-radius: 5px;

    transition: all .1s ease;
    pointer-events: none;
    padding: 10px;
    font-size: 12px;
    width: 239px;
}

#freebet-chartjs-tooltip .header {
    font-weight: bold;
    margin-bottom: 5px;
}

#freebet-chartjs-tooltip table {
    width: 100%;
    font-family: 'Poppins-regular', 'Sora', sans-serif;
}

#freebet-chartjs-tooltip table th {
    text-align: start;
    color: var(--lightGrey);
}

#freebet-chartjs-tooltip td {
    padding: 2px;
    font-size: 16px;
}

@media screen and (max-width: 1440px) {
    .free-bet-data-chart-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
}