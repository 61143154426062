.add-new-event-popup {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 1;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-event-panel {
  width: 400px;

  padding: 30px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  border-radius: 16px;
  background-color: var(--darkGrey);
}

.add-new-event-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  width: 100%;
}

.add-new-event-panel p {
  width: 100%;

  display: flex;
  align-items: center;

  color: var(--white);
  font-size: 1rem;
  font-family: 'Sora', sans-serif;
}

.add-new-event-panel form {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.add-new-event-panel form label {
  color: var(--white);
  margin-bottom: 10px;
}

.add-new-event-panel input {
  width: 300px;
  height: 50px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.add-new-event-panel button.disabled {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: not-allowed;
}