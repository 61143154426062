/* Container for the entire page */


  .admin-management-body-container {
    width: 100%;
    height: 100%;
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    justify-content: center;
    overflow: hidden;
  }

  .admin-management-tabs button {
    padding: 10px;
    
    border: none;
    background-color: transparent;

    color: #FFFFFF;
    font-family: 'Sora', sans-serif;
    font-size: 20px;

  }
  
  .admin-management-tabs button.active {
    border-bottom: 2px solid var(--primary);
  }

  @media screen and (max-width: 1200px) {
    .admin-management-body-container {
        flex-direction: column;
        gap: 20px;
    }
    
  }