/* Container for the sider */
.sider-tab {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  background-color: var(--panelBackground);
  /* Make sure to define this variable */
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
}

.sider-tab-selected {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  background-color: var(--darkGrey);
  /* Make sure to define this variable */
  outline: none;
  border: none;
  cursor: pointer;
}

.sider-tab:active {
  background-color: var(--darkGrey);
  opacity: 1;
  /* Make sure to define this variable */
}

.tab-icon {
  height: 25px;
  width: 25px;
}

.tab-label-selected {
  opacity: 1;

  transition: opacity 0.3s ease-in-out;
  
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 600;
}

.tab-label-unselected {
  opacity: 1;

  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  transition: opacity 0.3s ease-in-out;
}

.collapsed .tab-label-selected,
.collapsed .tab-label-unselected {
  opacity: 0;
}
