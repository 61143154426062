.promotions-container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.bonuses-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;

    max-width: 700px;
    min-height: 400px;
    box-sizing: border-box;

    margin: 14px;
    padding: 20px;

    border-radius: 12px;
    background-color: var(--panelBackground);
}

.bonuses-container h2 {
    color: var(--white);
}

.bonuses-container form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.bonuses-container form .form-group {
    display: flex;
    flex-direction: column;
}

.bonuses-container form .form-group label {
    font-size: 16px;
    color: var(--white);

    margin-bottom: 5px;
}

.bonuses-container form .form-group input {
    width: 416px;

    padding: 10px 12px 10px 12px;
    color: var(--lightGrey);

    background-color: #25252F;
    border: none;
    border-radius: 4px;
}

.bonuses-container form button {
    width: 200px;
    height: 40px;

    margin-left: auto;
    
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    
    border: none;
    border-radius: 4px;
    
    cursor: pointer;
}
.bonuses-container form button.button-sending {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: not-allowed;

    background-color: var(--darkOrangeButton);
    color: var(--primary);
}

.promotions-container .success-message,
.promotions-container .error-message {
    margin-top: 0;
}