.financials-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  margin-top: 14px;
  max-width: 100%;
  padding-bottom: 50px;

  border-radius: 12px;
  background-color: var(--panelBackground);
}

.financials-table .header-text-container {
  align-items: center;
  gap: 30px;
}