/* Container for the entire page */
.user-onboarding {
  width: 100%;
  max-width: 550px;
  height: 80%;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: var(--panelBackground);
  border-radius: 30px;
  margin-left: .5%;
  margin-right: .5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.header-text {
  color: var(--white);
  font-size: 20px;
}

.divider-line {
  height: 2px;
  width: 100%;
  background-color: var(--panelDividerLine);
}

.key-value-row {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.key-container {
  width: 50%;
  display: flex;
  flex-direction: row;;
}

.key-text {
  color: var(--white);
  font-weight: 100;
}

.value-text {
  color: var(--lightGrey);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-good {
  color: var(--positiveText);
}

.value-text-background-good {
  background-color: var(--darkGreenButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-text-neutral {
  color: var(--neutralText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-background-neutral {
  background-color: var(--darkYellowButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-text-negative {
  color: var(--negativeText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-background-negative {
  background-color: var(--darkRedButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 1400px) {
  .user-onboarding {
    height: 450px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .user-onboarding {
    height: max-content;
  }
}