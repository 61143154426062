.bar-graph-container {
  display: flex;
  gap: 10px;

  width: 100%;
  max-width: 1500px;
}

.bar-graph {
  width: 100%;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--panelBackground);
}

.bar-graph-header-row {
  width: 100%;
  padding: 10px 20px;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.bar-graph-header-row .searchInput {
  margin-left: auto;
}

.bar-graph-header-label {
  color: var(--white);
}

.bar-data-graph {
  width: 95%;
  height: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .bar-container .top-content {
    flex-direction: column;
  }

  .bar-stats-container,
  .bar-graph-container {
    width: 100%;
    justify-content: space-between;
  }

  .bar-stats-container .bar-stat {
    height: 150px;
  }

  .bar-table-row {
    width: max-content;

    margin: auto;
  }
}