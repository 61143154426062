.editing-mode * {
  box-sizing: border-box;
}

.editing-mode {
  width: 100%;
  max-width: 500px;
}

.editing-mode .personal-limits-section-container {
  width: 100%;
  border: none;
}

.editing-mode .personal-limits-section-item {
  width: 100%;

  flex-direction: column;
  align-items: center;
}

.editing-mode .personal-limits-section-item :first-child {
  width: 100%;
}

.editing-mode .personal-limits-section-item-label {
  width: 100%;

  flex-direction: row;
  justify-content: space-between;
}

.editing-mode .personal-limits-section-item-value {
  width: 100%;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
}

.editing-mode .personal-limits-section-item-value .error-message {
  margin-top: 10px;
}

.time-limit-decrease,
.time-limit-increase {
  width: 84px;

  padding: 8px 16px;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 51.04%, rgba(255, 255, 255, 0.08) 100%);
  border-image-slice: 1;

  color: var(--primary);
  font-size: 20px;
  text-align: center;
}

.editing-mode .personal-limits-section-item-value input {
  width: 100%;
  padding: 8px;

  border-radius: 4px;
  border: 1px solid var(--white);
  background-color: transparent;

  font-family: 'Sora', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: var(--white);
}

.editing-mode .personal-limits-section-item-value input.disabled {
  cursor: not-allowed;
  color: var(--lightGrey);
  border-color: var(--lightGrey);
}

.editing-mode .buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  padding: 20px 0;
}

.editing-mode .close-button {
  width: 184px;
  padding: 11px 32px;

  border: 1px solid var(--white);
  border-radius: 4px;

  color: var(--white);
  cursor: pointer;
  font-weight: 600;
}

.editing-mode .save-button {
  width: 184px;
  padding: 11px 32px;

  border: 1px solid var(--primary);
  background-color: var(--primary);
  border-radius: 4px;

  color: var(--white);
  cursor: pointer;
  font-weight: 600;
}

.editing-mode .save-button.disabled {
  background-color: var(--darkOrangeButton);
  border: none;
  color: var(--primary);
  cursor: not-allowed;
}

.editing-mode .saving {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  cursor: not-allowed;
}

.editing-mode .success-message {
  margin: 10px 0;
}

.editing-mode .personal-limits-section-container .slider {
  cursor: pointer;
}