.transactions-by-state-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  width: 98%;
  height: 100%;
  padding: 10px 0;

  overflow-y: scroll;
  overflow-x: hidden;

}

.transactions-by-state-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
 
  width: 100%;
  max-width: 600px;
}

.selected-state-label {
  width: 400px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--panelBackground);
  color: var(--white);
}