.table-container {
  width: 98%;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: max-content;
  max-height: 580px;
  overflow-y: scroll;

  border-radius: 5px;
}

.table-row {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 60px;

  background-color: var(--tableBodyBackground);
  border-top: 1px solid var(--panelDividerLine);
  ;
}

.table-header-row {
  background-color: var(--tableHeaderBackground);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 1500px) {
  .table-container {
    overflow-x: scroll;
    align-items: flex-start;
  }

  .table-row {
    width: max-content;
    min-width: max-content;
    max-width: max-content;
  }
}