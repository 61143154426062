.cell-big {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  max-width: 20%;
  min-width: 20%;
  height: 100%;
  border: none;
  outline: none;
  /* border-left: 1px solid var(--panelDividerLine); 
  border-right: 1px solid var(--panelDividerLine); */
}

.cell-biggest {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  max-width: 30%;
  min-width: 30%;
  height: 100%;
  border: none;
  outline: none;
}



.cell-small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10%;
  max-width: 10%;
  min-width: 10%;
  height: 100%;
  border: none;
  outline: none;
}

.table-header-row .cell-label {
  color: var(--tableHeaderFontColor);
  font-weight: 500;
  line-height: 24px;
}

.cell-label {
  font-size: 16px;
  color: var(--white);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
  /* max-width: 99.99%; */
}

.value-text-good {
  color: var(--positiveText);
}

.value-text-background-good {
  background-color: var(--darkGreenButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-text-neutral {
  color: var(--neutralText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-background-neutral {
  background-color: var(--darkYellowButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-text-negative {
  color: var(--negativeText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-background-negative {
  background-color: var(--darkRedButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-container {
  display: flex;
  flex-direction: row;
}

.view-user-button {
  height: 44px;
  /* width: 50px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkOrangeButton);
}

.view-user-button:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.edit-user-button {
  height: 44px;
  /* width: 50px; */
  margin-left: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkGreenButton);
}

.edit-user-button:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.delete-user-button-small {
  height: 44px;
  /* width: 50px; */
  margin-left: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkRedButton);
}

.delete-user-button-small:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.button-icon {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 1500px) {
   .cell-small {
    min-width: 200px;
  }

  .cell-big {
    min-width: 300px;
  }

  .cell-biggest {
    min-width: 400px;
  }
}