.alt-odds-table-container {
  width: 100%;
  max-width: 1000px;
  
  display: flex;
  justify-content: center;
}

.alt-odds-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 14px;
  max-width: 97%;
  border-radius: 12px;
  background-color: var(--panelBackground);
}

.alt-odds-table .table-container {
  padding: 10px 0;
  max-height: 500px;
}

.alt-odds-table .header-text-container {
  align-items: center;
  gap: 50px;
}

.alt-odds-table-container .search-input {
  display: flex;
  align-items: center;

  position: relative;
}

.alt-odds-table-container input {
  margin: 0;
}

.alt-odds-table-container button {
  margin: 0;
  position: absolute;
  right: 10px;

  width: 20px;  
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: none;
  background-color: var(--primary);

  color: var(--white);
  font-size: 12px;
  cursor: pointer;
}

.alt-odds-table-container button::after {
  content: "×";
}

.alt-odds-table .table-controls-container .prev-text {
  color: var(--primary);
}

.alt-odds-table .table-controls-container .disabled {
  color: var(--lightGrey);
  cursor: not-allowed;
}