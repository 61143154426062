.dropdown-multiple-checkbox {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  width: 200px;
  padding: 15px;
  
  background-color: var(--panelDividerLine);
  border-color: var(--panelDividerLine);
  border-radius: 8px;
  border-style: solid;
  
  color: var(--white);
  cursor: pointer;
}

.dropdown-content {
  width: 550px;

  display: block;

  position: absolute;
  right: 0;
  left: calc(100% - 350px);
  
  padding: 10px;
  margin-top: 10px;

  background-color: var(--panelDividerLine);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-content .dropdown-content-header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;

  padding: 0 10px;
}

.dropdown-content .dropdown-content-header .searchInput {
  width: 230px;
  height: 40px;
  
  padding-left: 10px;
  margin-left: 0;

  font-size: 11px;
}

.dropdown-content .dropdown-content-header span {
  color: var(--white);
  cursor: pointer;
}

.dropdown-content .dropdown-content-header button {
  margin-right: 30px;
  width: 80px;
  height: 40px;

  border-radius: 5px;
  border: none;

  cursor: pointer;
  color: var(--white);
  background-color: var(--primary);
}

.dropdown-content .options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-item {
  display: flex;
  align-items: center;

  width: 30%;
  padding: 5px;
  
  color: var(--white);
  cursor: pointer;
  font-size: 12px;
}

.dropdown-item input {
  margin-right: 10px;
  cursor: pointer;
}