.payment-toggle-container {
  padding: 20px;
  border-radius: 5px;
  min-width: 500px;
  margin: 20px auto 0 auto;

  background-color: var(--panelBackground);
}

.payment-toggle-container h2 {
  padding-bottom: 10px;

  border-bottom: 1px solid var(--lightGrey);

  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.toggle-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.toggle-section label {
  color: var(--white);
  font-size: 16px;
}

.both-settings {
  margin-top: 20px;
}

.condition-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  margin-bottom: 15px;
}

.condition-section label {
  color: var(--white);
  font-size: 14px;
}

.condition-section label b {
  color: var(--primary);
}

.condition-section select {
  cursor: pointer;
}

.condition-section select,
.condition-section input {
  width: 70px;

  padding: 5px;

  color: var(--lightGrey);
  font-size: 12px;

  background-color: #25252F;
  border: none;
}

.payment-toggle-container .success-message,
.payment-toggle-container .error-message {
  margin: 0 auto;
}