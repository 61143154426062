.individual-stats-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
}


.individual-stats-body {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.individual-stats-percentage {
  display: flex;
  justify-content: space-around;
}

.individual-stats-top-left,
.individual-stats-top-right {
  display: flex;
  justify-content: center;
  gap: 20px;

  width: 50%;
}

.individual-stats-percentage .individual-stat,
.individual-stats-commision .individual-stat {
  height: 200px;
  width: 45%;
  max-width: 300px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--panelBackground);
}

.individual-stats-percentage .stat-title,
.individual-stats-commision .stat-title {
  color: var(--white);
  font-size: 20px;
  text-align: center;
  max-width: 145px;
}

.individual-stats-percentage .stat-value,
.individual-stats-commision .stat-value {
  color: var(--lightGrey);
  font-size: 24px;
}

.individual-stats-commision {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.individual-stats-commision .stat-title {
  max-width: 225px;
}

@media screen and (max-width: 992px) {
  .individual-stats-percentage {
    gap: 20px;
  }

  .individual-stats-top-left,
  .individual-stats-top-right {
    flex-direction: column;
    align-items: center;
  }

  .individual-stats-percentage .individual-stat,
  .individual-stats-commision .individual-stat {
    width: 100%;
    height: 150px;
  }

  .individual-stats-commision {
    justify-content: space-around;
  }
}