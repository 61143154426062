.alt-odds-table-container .cell-small {
  flex: 1;

  max-width: none;
  min-width: none;
  width: auto;
}

.alt-odds-table-container .extra-small {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.alt-odds-table-container .cell-label.reason{
  white-space: pre-wrap;

  text-align: center;
  font-size: 14px;
}

.alt-odds-table-container .cell-small .cell-label {
  padding: 0 5px;
}

@media screen and (max-width: 1500px) {
  .alt-odds-table-container .table-container {
    overflow-x: hidden;
  }
  
  .alt-odds-table-container .table-row {
    width: 100%;
    max-width: none;
    min-width: none;
  }
}
@media screen and (max-width: 1400px) {
  .alt-odds-table-container .cell-label.reason {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 992px) {
  .alt-odds-table-container {
    width: max-content;
  }

  .alt-odds-table-container .cell-small {
    max-width: 200px;
  }
}