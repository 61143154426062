/* Container for the entire page */
.suspend-user-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.suspend-user-panel {
  width: 400px;
  height: 45%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--darkGrey);
}

.suspend-user-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.suspend-user-header-label {
  width: 100%;
  padding-left: 35px;
  display: flex;
  align-items: center;
  color: var(--white);
}

.close-button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  width: 30px;
  height: 30px;
  color: var(--white);
}

.close-icon:hover {
  width: 30px;
  height: 30px;
  color: var(--lightGrey);
  cursor: pointer;
}

.current-status-label {
  color: var(--lightGrey);
  margin-top: 60px;
}

.suspend-value-text-good {
  color: var(--positiveText);
}

.suspend-value-text-background-good {
  background-color: var(--darkGreenButton);
  height: 30px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.suspend-value-text-neutral {
  color: var(--neutralText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.suspend-value-text-background-neutral {
  background-color: var(--darkYellowButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspend-value-text-negative {
  color: var(--negativeText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.suspend-value-text-background-negative {
  background-color: var(--darkRedButton);
  height: 30px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.suspend-reason-input {
  width: 300px;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspend-button-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--primary);
  color: var(--white);
}

.submit-button-inactive {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--primary);
}

.submit-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

.submit-button-text {
  color: var(--white);
}

.submit-button-text:hover {
  color: var(--primary);
}

.submit-button-sending {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--white);
}

.error-message{
  color: var(--negativeText);
  margin-bottom: 10px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}
