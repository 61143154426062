.payments-body-container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.payments-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  max-width: 97%;
  min-height: 400px;

  margin: 14px;
  padding-bottom: 20px;

  border-radius: 12px;
  background-color: var(--panelBackground);
}

.payments-body-container .header-text-container {
  justify-content: space-between;
  align-items: center;
}

.payments-body-container .table-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1024px;

  min-height: 180px;
  margin-left: 30px;

  overflow-y: auto;
  overflow-x: auto;
}

.payments-content-container {
  display: flex;
  justify-content: center;
  
  width: 100%;
}

.payments-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  min-width: 328px;
}

.payments-info .success-message,
.payments-info .error-message {
  margin: 0;
}

.payments-info .stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payments-info .stat-title {
  display: flex;
  align-items: center;

  color: var(--lightGrey);
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.003em;
}

.payments-info .stat-value {
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.003em;
  text-align: left;
}

.payments-info button {
  height: 50px;
  width: 300px;
  background-color: var(--primary);
  color: var(--white);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin: 0 1em;
}

.add-new-event-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .payments-content-container .table-row {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}