.tou-table-container {
  width: 100%;

  display: flex;
  justify-content: center;
}

.tou-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  margin: 14px 0;
  max-width: 90%;
  padding-bottom: 50px;

  border-radius: 12px;
  background-color: var(--panelBackground);
}

.tou-table .table-container {
  max-height: 500px;
  overflow-y: scroll;
}

.tou-table .header-text-container {
  align-items: center;
  gap: 50px;
}

.tou-table .table-controls-container .prev-text {
  color: var(--primary);
}

.tou-table .table-controls-container .disabled {
  color: var(--lightGrey);
  cursor: not-allowed;
}