.delete-user {
  margin-right: auto;
}

.delete-user .delete-user-button {
  height: 50px;
  width: 200px;
  background-color: #E74C3C;
  color: var(--white);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin: 0 1em;
}

.delete-user .delete-user-button:hover {
  height: 50px;
  width: 200px;
  background-color: #E74C3C;
  color: var(--white);
  
  cursor: pointer;
}