.total-redeemable-graph {
    width: 90%;
    min-height: 600px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--panelBackground);
}

.total-redeemable-graph-header-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.left-half-graph-header {
    width: 50%;
    display: flex;
    align-items: center;
}

.right-half-graph-header {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.graph-filter {
    justify-content: center;
    width: 180px;
    height: 54px;
    background-color: var(--panelDividerLine);
    border-color: var(--panelDividerLine);
    color: var(--white);
    border-radius: 8px;
    border-style: solid;
    outline: none;
    margin-right: 20px;
}

.total-redeemable-graph-header-label {
    color: var(--white);
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 20px;
}

.total-redeemable-graph-container {
    width: 95%;
    height: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
}