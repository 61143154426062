.total-email-verified {
    height: 270px; /* Ensure it takes full height of the parent */
    width: 300px; /* Ensure it takes full width of the parent */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items at the start to avoid centering issues */
    justify-content: center;
    background-color: var(--panelBackground);
}

.total-email-title-container {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lightGrey);
}

.total-email-graph-container {
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .total-email-verified {
        width: 100%;
    }
}



