/* Container for the entire page */
.edit-bet-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-bet-panel {
  width: 500px;
  height: 700px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--darkGrey);
}

.edit-bet-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit-bet-header-label {
  width: 100%;
  padding-left: 35px;
  display: flex;
  align-items: center;
  color: var(--white);
}

.close-button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  width: 30px;
  height: 30px;
  color: var(--white);
}

.close-icon:hover {
  width: 30px;
  height: 30px;
  color: var(--lightGrey);
  cursor: pointer;
}

.current-bet-status-label {
  color: var(--lightGrey);
  margin-top: 60px;
  margin-bottom: 40px;
}

.current-bet-status-value {
  color: var(--white);
}

.suspend-button-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--primary);
  color: var(--white);
}

.submit-button-inactive {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--primary);
}

.submit-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

.submit-button-text {
  color: var(--white);
}

.submit-button-text:hover {
  color: var(--primary);
}

.submit-button-sending {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--white);
}

.error-message{
  color: var(--negativeText);
  margin-bottom: 10px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}

.success-message{
  color: var(--positiveText);
  margin-bottom:25px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}