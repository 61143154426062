.panel-container {
  width: 98%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-button-container {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4%;
  cursor: pointer;
}

.back-arrow {
  color: var(--lightGrey);
  margin-right: 10px;
}

.back-text {
  color: var(--lightGrey)
}

.body-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wallet-container {
  width: 97%;
}

@media screen and (max-width: 1400px) {
  .panel-container {
    height: max-content;
  }
}

@media screen and (max-width: 1200px) {

  .body-container {
    flex-wrap: wrap;
  }
}