html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.app-container {
  min-height: 100vh;
  /* Ensures the container is at least as tall as the viewport */
  background-color: #252729;
  /* Your desired background color */
  display: flex;
  flex-direction: column;
}

.mobile-app-container {
  min-height: 100vh;
  /* Ensures the container is at least as tall as the viewport */
  background-color: #252729;
  /* Your desired background color */
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Poppins-regular', 'Sora', sans-serif;
}

.app-container, .mobile-app-container {
  font-family: 'Poppins-regular', 'Sora', sans-serif;
  /* Ensure it's applied to your containers */
}

button {
  font-family: 'Poppins-regular', 'Sora', sans-serif !important;
}

/* Color Variables */

:root {
  --black: #000000;
  --white: #FFFFFF;
  --primary: #FD6F0F;
  --orangeButtonPressed: #9c4810;
  --darkGrey: #252729;
  --lightGrey: #777777;
  --panelBackground: #1A1A22;
  --panelDividerLine: #25252F;
  --panelHeaderTextGrey: #BBBBB2;
  --backgroundColor: #000000;
  --undepositedColor: #E7D2C2;
  --darkOrangeButton: #30241C;
  --darkGreenButton: #1B2927;
  --darkRedButton: #452223;
  --darkYellowButton: #453B22;
  --positiveText: #26BF94;
  --negativeText: #FC3939;
  --neutralText: #FCE839;

  --tableHeaderBackground: #25252F;
  --tableBodyBackground: #2F2F39;
  --tableHeaderFontColor: #92929E;
}
/* :root {
  --black: #000000;
  --white: #FFFFFF;
  --primary: #FD6F0F;
  --orangeButtonPressed: #9c4810;
  --darkGrey: #25252F;
  --lightGrey: #777777;
  --panelBackground: #1A1A22;
  --backgroundColor: #000000;
  --panelDividerLine: #2F2F2F;
  --panelHeaderTextGrey: #BBBBB2;
  --undepositedColor: #E7D2C2;
  --darkOrangeButton: #30241C;
  --darkGreenButton: #1B2927;
  --darkRedButton: #452223;
  --darkYellowButton: #453B22;
  --positiveText: #26BF94;
  --negativeText: #FC3939;
  --neutralText: #FCE839;
} */

.pageContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--backgroundColor); /* Make sure to define this variable */
 
}

/* Container for the content area */
.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  margin-top: 100px;
  transition: margin 0.3s ease-in-out;
}

.contentContainer.expanded {
  margin-left: 280px;
}

.contentContainer.collapsed {
  margin-left: 120px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: var(--darkGrey);
}

::-webkit-scrollbar-thumb {
  background: #09090C;
  border-radius: 5px;
  height: 4px;
  width: 4px;
}

.react-datepicker-wrapper input {
  width: 160px;
  padding: 15px;
  font-size: 12px;

  background-color: var(--panelDividerLine);
  border-color: var(--panelDividerLine);
  border-radius: 8px;
  border-style: solid;

  color: var(--white);
  font-family: 'Poppins-regular', 'Sora', sans-serif;

  cursor: pointer;
}

.react-datepicker,
.react-datepicker__header {
  background-color: var(--panelDividerLine);
  border: none;
}

.react-datepicker__header {
  border-bottom: 1px solid var(--panelHeaderTextGrey);
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__header {
  color: var(--white);
}

.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__day:not([aria-disabled=true]):hover,
.react-datepicker__close-icon::after,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: var(--primary);
}

.react-datepicker__day,
.react-datepicker__month .react-datepicker__month-text {
  color: var(--white);
  padding: 5px;
}

/*Chrome, Safari, Edge e Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*Firefox */
input[type=number] {
    -moz-appearance: textfield;
}