.financials-stats-container {
  width: 50%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  height: max-content;
}

.financials-stats-container .financials-stat {
  height: 200px;
  width: 45%;
  max-width: 300px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--panelBackground);
}