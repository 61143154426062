
  
  .affiliate-body-container {
    width: 98%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    padding: 12px 0;
  }

  .affiliate-body-container .searchInput {
    width: max-content;
    height: 49px;
  
    padding-left: 20px;
    margin-right: 0;
  
    background-color: var(--panelBackground);
    border-color: var(--panelDividerLine);
    color: var(--lightGrey);
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    outline: none;
  }

  .refresh-button {
    width: 140px;
    margin-left: auto;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--primary);
    color: var(--white);
  }
  
  .refresh-button:hover,
  .refresh-button.refreshing {
    background-color: var(--darkOrangeButton);
    color: var(--primary);
    cursor: pointer;
  }

  .refresh-button:disabled {
    cursor: not-allowed;
  }
  
  .refresh-button-sending {
    width: 140px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--darkOrangeButton);
    color: var(--white);
  }