
.ftd-listing-table .cell-small {
  flex: 1 1;
  max-width: 400px;
  min-width: 200px;
  width: auto;
}

@media screen and (max-width: 1500) {
  .ftd-listing-table .table-row {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

