/* Container for the entire page */
.user-actions {
  width: 100%;
  max-width: 550px;
  height: 80%;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: var(--panelBackground);
  border-radius: 30px;
  margin-left: .5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.header-text {
  color: var(--white);
  font-size: 20px;
}

.divider-line {
  height: 2px;
  width: 100%;
  background-color: var(--panelDividerLine);
}

.buttons-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.action-button {
  height: 50px;
  width: 46%;
  background-color: var(--primary);
  color: var(--white);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-left: 10px;
}

.action-button-sending {
  height: 50px;
  width: 46%;
  background-color: var(--darkOrangeButton);
  color: var(--white);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-left: 10px;
}

.action-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

.button-label {
  color: var(--white);
  text-align: center;
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.delete-user-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-user-button {
  height: 50px;
  width: 46%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: var(--negativeText);
  color: var(--white);
}

.delete-user-button:hover {
  height: 50px;
  width: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkRedButton);
  color: var(--negativeText);
  cursor: pointer;
}

/* Container for the content area */

@media screen and (max-width: 1400px) {
  .user-actions {
    height: 450px;
    padding-bottom: 50px;
  }
}