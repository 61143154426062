/* Container for the entire page */
.payment-instruments-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-instruments-panel {
  width: 700px;
  height: 70%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--darkGrey);
}

.payment-instruments-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.payment-instruments-header-label {
  width: 100%;
  padding-left: 35px;
  display: flex;
  align-items: center;
  color: var(--white);
}

.delete-button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  width: 30px;
  height: 30px;
  color: var(--white);
}

.close-icon:hover {
  width: 30px;
  height: 30px;
  color: var(--lightGrey);
  cursor: pointer;
}

.current-status-label {
  color: var(--lightGrey);
  margin-top: 60px;
}

.delete-reason-input {
  width: 500px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-check-container {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.delete-check-text {
  color: var(--white);
  margin-left: 20px;
}

.payment-instruments-button-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--negativeText);
  color: var(--white);
}

.delete-button-inactive {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkRedButton);
  color: var(--lightGrey);
}

.delete-button:hover {
  background-color: var(--darkRedButton);
  color: var(--negativeText);
  cursor: pointer;
}

.delete-button-text {
  color: var(--white);
}

.delete-button-text:hover {
  color: var(--primary);
}

.delete-button-sending {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--white);
}

.delete-error-message{
  color: var(--negativeText);
  margin-bottom: 10px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}

.delete-success-message{
  color: var(--positiveText);
  margin-bottom: 10px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}

.banks-container {
  height: 200px;
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.bank-header-row {
  width: 70%;
  height: 75px;
  display: flex;
  color: var(--white);
}


.bank-row {
  width: 70%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bank-label {
  color: var(--lightGrey);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-one-bank-label {
  color: var(--lightGrey);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-one-bank-button-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-one-bank-button {
  width: 140px;
  height: 40px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--negativeText);
  color: var(--white);
}

.delete-one-bank-button:hover {
  background-color: var(--darkRedButton);
  color: var(--negativeText);
  cursor: pointer;
}

.delete-one-bank-button-text:hover {
  color: var(--negativeText);
}
