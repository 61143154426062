.financials-container {
  width: 100%;
  padding-left: 10px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
}

.financials-container .top-content {
  width: 100%;
  min-height: 400px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.financials-container .stat-title {
  color: var(--white);
  font-size: 20px;
  text-align: center;

  max-width: 230px;
}

.financials-container .stat-value {
  color: var(--lightGrey);
  font-size: 24px;
}

.financials-container .bottom-content {
  width: 100%;
}