.edit-admin {
  height: 700px;
  width: 500px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--panelBackground);
}

.edit-admin-header-text {
  color: var(--white);
  margin-top: 25px;
  margin-bottom: 30px;
  font-size: 20px;
}

.edit-admin-drop-down-container {
  margin-top: 50px;
  margin-bottom: 30px;
}

.admin-permissions-row {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  margin-top: 25px;
}

.admin-permissions-label {
  color: var(--lightGrey);
  margin-left: 25px;
}

.create-admin-button-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.submit-button {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--primary);
  color: var(--white);
}

.submit-button-inactive {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--primary);
}

.submit-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

.submit-button-text {
  color: var(--white);
}

.submit-button-text:hover {
  color: var(--primary);
}

.submit-button-sending {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--white);
}

.error-message{
  color: var(--negativeText);
  margin-top: 25px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}

.success-message{
  color: var(--positiveText);
  margin-top:25px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}