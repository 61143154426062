.email-status-by-day-container {
  display: flex;
  justify-content: center;
  gap: 20px;

  width: 100%;
}

.email-status-by-day-graph {
  width: 90%;
  min-height: 600px;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--panelBackground);
}

.email-status-by-day-graph-header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.email-status-by-day-graph-header-label {
  color: var(--white);
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 20px;
}

.email-status-by-day-container .left-half-graph-header {
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-status-by-day-graph-container {
  width: 95%;
  height: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aquamarine; */
}

@media screen and (max-width: 1024px) {
  .email-status-by-day-container {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}