
.financials-table .cell-small {
  flex: 1 1;
  max-width: 400px;
  min-width: 300px;
  width: auto;
}

@media screen and (max-width: 1024px) {
  .financials-table {
    width: max-content;
  }
}