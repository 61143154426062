/* Container for the entire page */
.edit-wallet-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-wallet-panel {
  width: 70%;
  height: 60%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--darkGrey);
}

.edit-wallet-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit-wallet-header-label {
  width: 100%;
  padding-left: 60px;
  display: flex;
  align-items: center;
  color: var(--white);
}

.close-button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  width: 30px;
  height: 30px;
  color: var(--white);
}

.close-icon:hover {
  width: 30px;
  height: 30px;
  color: var(--lightGrey);
  cursor: pointer;
}

.edit-wallet-section-header {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.edit-wallet-section-header-text {
  color: var(--lightGrey);
  margin-left: 30px;
}

.current-balances-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.balance-block {
  width: 140px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.balance-label-text {
  color: var(--lightGrey);
  font-size: 12px;
  margin-top: 20px;
}

.balance-value-text {
  color: var(--white);
  font-size: 24px;
}

.action-statement-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.action-statement-text {
  color: var(--white);
  margin-right: 20px;
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.amount-input {
  width: 180px;
  height: 50px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-input[type="number"]::-webkit-outer-spin-button,
.amount-input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.reason-input {
  width: 690px;
  height: 50px;
  margin-top: 30px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-right: 100px;
}

.submit-button {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--primary);
  color: var(--white);
}

.submit-button-inactive {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--lightGrey);
}

.submit-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}

.submit-button-text {
  color: var(--white);
}

.submit-button-text:hover {
  color: var(--primary);
}

.submit-button-sending {
  width: 140px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  color: var(--white);
}

.error-message{
  color: var(--negativeText);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}
