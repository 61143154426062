.suspend-user-panel {
  width: max-content;
  max-width: 900px;
  min-width: 422px;
  height: max-content;
  min-height: 450px;

  padding: 30px;
}

.suspend-user-header {
  padding: 10px 0;
}

.suspend-user-header-label {
  padding-left: 0;
  font-size: 20px;
}

.promotion-popup .promotion-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 20px;
  max-height: 500px;

  overflow-y: auto;
}

.promotion-popup .promotion {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 400px;
  padding: 10px;
  position: relative;

  border: 1px solid #7777774a;
  border-radius: 10px;
}

.promotion-popup .promotion .promotion-expire-time {
  margin: 0;

  font-size: 12px;
  color: var(--white);
}

.promotion-popup .promotion .promotion-title {
  margin: 0;

  font-size: 18px;
  color: var(--white);
}

.promotion-popup .promotion .promotion-description {
  margin: 0;

  font-size: 12px;
  color: var(--white);
}

.promotion .delete-container {
  position: absolute;

  padding: 5px;
  border: 1px solid #7777774a;
  border-radius: 0px 10px 0 5px;

  right: 0px;
  top: 0px;
}

.promotion .delete-icon {
  width: 20px;
  
  cursor: pointer;
}
.promotion .confirmation-container {
  width: 100%;

  margin-top: 10px;
}

.promotion .confirmation-container span {
  display: flex;
  justify-content: center;

  text-align: center;
  font-size: 12px;
  color: var(--white);
}

.promotion .confirmation-container input {
  box-sizing: border-box;
  width: 100%;
  
  margin: 0;
}

.promotion-popup .success-message,
.promotion-popup .error-message {
  margin: 0;
}