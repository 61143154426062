/* Container for the entire page */

.bet-cell {
  width: 11.11%;
  min-width: 11.11%;
  max-width: 11.11%;
}

.bet-cell-label {
  font-size: 16px;
  color: var(--panelHeaderTextGrey);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.bet-id-cell-label {
  font-size: 10px;
  color: var(--panelHeaderTextGrey);
  margin-left: 10px;
}

/* .actions-container {
  display: flex;
  flex-direction: row;
} */

.view-user-button {
  height: 44px;
  /* width: 50px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkOrangeButton);
}

.view-user-button:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.edit-user-button {
  height: 44px;
  /* width: 50px; */
  /* margin-left: 12px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkGreenButton);
}

.edit-user-button:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.edit-user-button.disabled:hover,
.edit-user-button.disabled {
  background-color: var(--panelDividerLine);
  cursor: not-allowed;
}

.edit-user-button.disabled img {
  filter: grayscale(100%);
}

.delete-user-button-small {
  height: 44px;
  /* width: 50px; */
  margin-left: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--darkRedButton);
}

.delete-user-button-small:hover {
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.button-icon {
  height: 30px;
  width: 30px;
}

.win-type-text-good {
  color: var(--positiveText);
}

.win-type-text-background-good {
  background-color: var(--darkGreenButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.win-type-text-neutral {
  color: var(--neutralText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.win-type-text-background-neutral {
  background-color: var(--darkYellowButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.win-type-text-negative {
  color: var(--negativeText);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.win-type-text-background-negative {
  background-color: var(--darkRedButton);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-text-unsettled {
  color: var(--lightGrey);
  overflow: hidden;          /* Hide the overflow text */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
}

.value-text-background-unsettled {
  background-color: var(--panelDividerLine);
  height: 30px;
  width: 110px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1500px) {

  .bet-cell {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
  
}
