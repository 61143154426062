.cash-in-out-container {
  display: flex;
  justify-content: center;
  gap: 10px;

  width: 100%;
  max-width: 600px;
}
.cash-in-graph,
.selected-state {
  height: 200px;
  width: 300px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--panelBackground);
}

.cash-in-graph h2,
.selected-state h2 {
  color: var(--white);
  font-size: 20px;
}

.cash-in-graph p,
.selected-state p {
  margin: 0;
  
  color: var(--white);
}

.cash-in-graph p span {
  color: var(--lightGrey);
}

@media screen and (max-width: 1024px) {
  .cash-in-out-container {
    justify-content: center;
  }
}