/* Container for the entire page */
.user-table {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 14px;
  max-width: 97%;
  border-radius: 12px;
  background-color: var(--panelBackground); /* Make sure to define this variable */
}

.head-controls {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-text-container {
  width: 100%;
  display: flex;
}

.header-text{
  color: var(--white);
  font-size: 20px;
  margin-left: 30px;
}

.bet-searchInput {
  width: 400px;
  height: 50px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--lightGrey);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  margin-right: 20px;
}

.drop-down-container {
  margin-right: 20px;
}

.search-button {
  height: 50px;
  width: 60px;
  margin-right: 20px;
  border-radius: 8px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.search-button:active {
  background-color: var(--orangeButtonPressed);
}

.search-icon {
  width: 26px;
  height: 26px;
}


.data-rows-container {
  max-height: 420px;
  width: 100%;
}

.table-controls-container {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
}

.prev-text {
  color: var(--white);
  margin-right: 20px;
  cursor: pointer;
}

.next-text {
  color: var(--primary);
  cursor: pointer;
}



