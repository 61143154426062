.deposit-table-container .cell-small {
  flex: 1;

  max-width: 400px;
  min-width: 200px;
  width: auto;
}

.deposit-table-container .extra-small {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.deposit-table-container .cell-label.reason{
  white-space: pre-wrap;

  text-align: center;
  font-size: 14px;
}

.deposit-table-container .cell-small .cell-label {
  padding: 0 5px;
}

@media screen and (max-width: 1400px) {
  .deposit-table-container {
    width: max-content;
  }

  .deposit-table-container .cell-small {
    max-width: 200px;
    min-width: 200px
  }

  .deposit-table-container .cell-label.reason {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 992px) {
  .deposit-table-container {
    width: max-content;
  }

  .deposit-table-container .cell-small {
    max-width: 200px;
  }
}