.top-performers {
  width: 50%;

  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--panelBackground);
}

.top-performers-title {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white);
}

.top-performers-graph-container {
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .top-performers {
    width: 100%;
  }

  .top-performers-graph-container {
    width: 300px;
    height: 300px;
  }
}