.save-button {
  display: flex;
  justify-content: center;

  width: 184px;
  padding: 11px 32px;

  margin-left: auto;
  
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border-radius: 4px;

  color: var(--white);
  cursor: pointer;
  font-weight: 600;
}

.save-button.disabled {
  background-color: var(--darkOrangeButton);
  border: none;
  color: var(--primary);
  cursor: not-allowed;
}

.saving {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: var(--darkOrangeButton);
  cursor: not-allowed;
}