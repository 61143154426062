.codes-body-container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.codes-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  max-width: 97%;
  min-height: 400px;
  
  margin: 14px;
  padding-bottom: 20px;

  border-radius: 12px;
  background-color: var(--panelBackground);
}

.codes-body-container .header-text-container {
  justify-content: space-between;
  align-items: center;
}

.add-new-event-button {
  height: 50px;
  width: 300px;
  background-color: var(--primary);
  color: var(--white);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin: 0 1em;
}

.add-new-event-button:hover {
  background-color: var(--darkOrangeButton);
  color: var(--primary);
  cursor: pointer;
}