/* Container styling */
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--black); /* Assuming AppColors are defined as CSS variables */
  flex-grow: 1;
}

/* Branding column styling */
.brandingColumn {
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.brandingImage {
  height: 100%;
}

/* Form column styling */
.formColumn {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--panelBackground);
}

.formContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Text styling */
.loginHeaderText {
  color: var(--white);
  font-size: 30px;
}

.fieldLabelText {
  color: var(--white);
  font-size: 18px;
  text-align: left;
}

/* Input field styling */
.fieldInput {
  width: 406px;
  height: 50px;
  background-color: var(--panelBackground);
  border-color: var(--panelDividerLine);
  padding-left: 20px;
  color: var(--white);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
}

.fieldInput:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--panelBackground) inset;
  -webkit-text-fill-color: var(--white);
}

/* For Firefox */
.fieldInput:-moz-autofill {
  box-shadow: 0 0 0px 1000px var(--panelBackground) inset;
  -moz-text-fill-color: var(--white);
}

/* General input autofill override */
.fieldInput:-webkit-autofill,
.fieldInput:-moz-autofill,
.fieldInput:-ms-autofill,
.fieldInput:-o-autofill {
  background-color: var(--panelBackground) !important;
  color: var(--white) !important;
  border-color: var(--panelDividerLine) !important;
}

/* Button styling */
.loginButton {
  width: 440px;
  height: 60px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  outline: none;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
}

.loginButton:hover {
  width: 440px;
  height: 60px;
  background-color: var(--darkOrangeButton);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  outline: none;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
}

.loginButton-sending {
  width: 440px;
  height: 60px;
  background-color: var(--darkOrangeButton);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  outline: none;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
}

.loginButton:active {
  background-color: var(--orangeButtonPressed); /* Change to darkGrey when pressed */
}

.loginButtonText {
  color: var(--white);
  font-size: 16px;
}

.loginButtonText-inactive {
  color: var(--primary);
  font-size: 16px;
}

.login-error-message-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message{
  color: var(--negativeText);
  margin-top: 25px;
  font-size: 12px;
  max-width: 90%;
  text-align: center;
}
