.affiliate-kpis-container {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.affiliate-kpis-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.affiliate-kpi-top {
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.affiliate-kpi.tilt-card {
  animation: tilt 1.5s ease-in-out infinite;
}

@keyframes tilt {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}

.affiliate-kpi {
  height: 200px;
  width: 45%;
  max-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: var(--panelBackground);
  border-radius: 16px;
}

.affiliate-kpi-title {
  max-width: 145px;
  height: 50px;

  color: var(--white);
  font-size: 20px;
  text-align: center;
}

.affiliate-kpi-value {
  color: var(--lightGrey);
  font-size: 24px;
}

.affiliate-kpi p {
  margin: 0;
  height: 20px;
}

.affiliate-kpi .increase {
  color: green;
}

.affiliate-kpi .decrease {
  color: red;
}

.affiliate-kpis-graphs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  width: 100%;
}

.affiliates-bottom-graphs {
  width: 100%;
  max-width: 1500px;
  min-height: 400px;

  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media screen and (max-width: 1440px) {
  .affiliate-kpi-top {
    flex-wrap: wrap;
  }

  .affiliate-kpi {
    width: 25%;
    max-width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 1024px) {
  .affiliates-bottom-graphs {
    flex-direction: column;
  }


}