/* Container for the sider */
.sider {
  height: 100vh;
  padding: 0 15px 100px 15px;

  position: fixed;
  margin-top: 83px;

  transition: width 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--panelBackground);
  /* Make sure to define this variable */
}

.sider.expanded {
  width: 250px; /* Defina a largura quando expandido */
}

.sider.collapsed {
  width: 60px; /* Defina a largura quando contraído */
}

.sider-top-icons {
  width: 100%;
  /* height: 64px; */
  padding: 12px 0 12px 32px;

  display: flex;
  align-items: center;
  gap: 30px;
}

.sider-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  padding-top: 15px;
}

.sider-buttons button:hover {
  background-color: var(--darkGrey);
}

.menu-icon {
  cursor: pointer;
}