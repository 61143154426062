.codes-body-container .cell-small {
  flex: 1;

  max-width: 400px;
  min-width: 300px;
  width: auto;
}

.codes-body-container .cell-small .cell-label {
  padding: 0 5px;
}
